import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=179

const PravidlaEventu: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/pravidla_eventu">Pravidla eventů</Link></h1>
      </div>
      <p><strong>Obecně</strong></p>
      <p>1/ V průběhu všech eventů (vyjma PvP eventů) jsou zabíjení hráčů a útoky na GM postavu zakázány (není-li to výslovně povoleno). Porušení tohoto pravidla bude trestáno podle závažnosti prohřešku.</p>
      <p>2/ Uvedená pravidla může GM kdykoliv změnit, přitom však musí dbát na to, aby taková změna nezvýhodnila žádnou z postav, které se eventu účastní.</p>
      <p><span id="more-179"></span></p>
      <table cellSpacing="3" cellPadding="3">
        <tbody>
          <tr>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Hledání prince" to="/event_hledani_prince">HLEDÁNÍ PRINCE</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Geocaching" to="/event_geocaching">GEOCACHING</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event screen" to="/event_screen">SCREEN EVENT</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Kde je GM" to="/event_kde_je_gm">KDE JE GM?</Link></strong></span></td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Poslední bojovník" to="/event_posledni_bojovnik">POSLEDNÍ BOJOVNÍK</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Orientační" to="/event_orientacni">ORIENTAČNÍ</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"><span style={{ textDecoration: 'underline' }}><strong><Link title="Event Otázkový" to="/event_otazkovy">OTÁZKOVÝ</Link></strong></span></td>
            <td style={{ textAlign: 'center' }} width="150"></td>
          </tr>
        </tbody>
      </table>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PravidlaEventu

export { Head } from '../components/defaultHtmlHead'